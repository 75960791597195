






























































































































import { Validations } from 'vuelidate-property-decorators'
import { validationMixin } from 'vuelidate'
import { required, email, minLength } from 'vuelidate/lib/validators'
import controller from '@/app/ui/controllers/UserManagementController'
import { Component, Vue, Watch } from 'vue-property-decorator'
import Button from '@/app/ui/components/Button/index.vue'
import TextInput from '@/app/ui/components/TextInput/index.vue'
import DropdownSelect from '@/app/ui/components/DropdownSelect/index.vue'
import LoadingOverlay from '@/app/ui/components/LoadingOverlay/index.vue'
import Modal from '@/app/ui/components/Modal/index.vue'
import RoleToggle from '../components/RoleToggle/index.vue'
import EditIcon from '@/app/ui/assets/edit_icon.vue'
import { AccessMenu, Role } from '@/domain/entities/Account'
import { EventBus, EventBusConstants, Utils } from '@/app/infrastructures/misc'

@Component({
  mixins: [validationMixin],
  components: {
    Button,
    EditIcon,
    DropdownSelect,
    RoleToggle,
    TextInput,
    LoadingOverlay,
    Modal,
  },
})
export default class CreateUserManagement extends Vue {
  controller = controller
  successModal = false
  confirmationModal = false
  form = {
    email: '',
    role: {} as Record<string, any>,
    accessMenu: [] as number[],
  }
  hasChanged = {
    email: false,
    role: false,
    accessMenu: false,
  }

  created(): void {
    controller.getRoles()
    controller.getMenus()
  }

  @Validations()
  validations(): Record<string, unknown> {
    return {
      form: {
        email: { required, email },
        role: { required },
        accessMenu: { required, minLength: minLength(1) },
      },
    }
  }

  get roles(): Record<string, unknown>[] {
    return this.controller.roleData.map(item => ({
      label: Utils.roleName(item.role as string),
      value: item.role,
    }))
  }

  get userMenuList(): Record<string, unknown>[] {
    const groups: string[] = []
    for (let i = 0; i < controller.accessMenuData.length; i++) {
      if (!groups.includes(`${controller.accessMenuData[i].group}`)) {
        groups.push(`${controller.accessMenuData[i].group}`)
      }
    }

    const menuList = groups.map(item =>
      Object.assign(
        {},
        {
          group: item,
          data: controller.accessMenuData
            .filter(m => m.group === item)
            .map(menu => {
              return new AccessMenu(
                menu.id,
                menu.slug,
                menu.name,
                menu.group,
                false
              )
            }),
        }
      )
    )

    return menuList
  }

  private onToggled(event: { checked: boolean; value: number }) {
    this.hasChanged.accessMenu = true
    if (event.checked) {
      this.form.accessMenu.push(event.value)
    } else {
      this.form.accessMenu = this.form.accessMenu.filter(
        item => item !== event.value
      )
    }
  }

  private onCreateAccount() {
    this.confirmationModal = false

    this.controller.createAccount({
      email: this.form.email,
      role: this.form.role.value,
      accessMenu: this.form.accessMenu,
    })

    this.hasChanged = {
      email: true,
      role: true,
      accessMenu: true,
    }
  }

  private onCloseSuccessModal() {
    this.successModal = false
    this.$router.push({ name: 'UserManagementPage' })
  }

  private getRoleName(role: string) {
    return Utils.roleName(role)
  }

  @Watch('controller.accessMenuData')
  private syncMenu() {
    this.controller.syncMenu()
  }

  @Watch('controller.isCreateAccountSuccess')
  onIsCreateAccountSuccessChanged(data: boolean): void {
    if (data) {
      this.successModal = true

      this.controller.setIsCreateAccountSuccess(false)
    }
  }

  beforeDestroy(): void {
    this.controller.setIsCreateAccountSuccess(false)
  }
}
